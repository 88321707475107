<template>
	<div>
    Signing out...
  </div>
</template>	
<script type="text/javascript"></script>
<script>

  import Cockpitsapi from '@/services/api/cockpits'
  import auth from '@/cognito.js';

  //import Signinapi from '@/services/api/signin'

  export default{
    
    data(){
      return{
        //loggedinuser:{}
      }
    },
    computed: {


      loggedinuser(){
        return this.$store.getters.user
      },

      token(){
        return this.$store.getters.token
      },

    },
    filters: {},
    mounted() { 

      let token = this.$cookie.get('usertoken');

      let self = this   

      self.$store.dispatch('fecthUserDetails', token).then( function(response){
         Cockpitsapi.Logevent( { "message":"user sign-out", "object":"users", "category":"logout user", "t":self.token, "payload": JSON.stringify( { "userid":self.loggedinuser._id, "time":Date.now() } ) } )
      })         
          

          this.$cookie.set('usertoken', '');
          this.$cookie.set('organizationkey', '');
          this.$cookie.set('signinmethod', "");

          this.$cookie.delete('usertoken');
          this.$cookie.delete('organizationkey');
          this.$cookie.delete('signinmethod');

          this.$cookie.delete('showorghelp');

          localStorage.token = ""
          
          auth.logout();

          //localStorage.setItem('message',JSON.stringify( {'command':'signoutall'} ));
          //localStorage.removeItem('message');
          //this.$store.dispatch('doSignOut')
          //.then(() =>{
           setTimeout(function() {
            location.href='/'
            //self.$router.push()
          },3000)
         //})

        },


          methods: {
          },
        }
      </script>